import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layouts"
import ContentModules from "../../components/Page/ContentModules"
import SEO from "../../components/seo"
import { useTranslationContext } from "../../context/TranslationContext"
const canonicals = {
  ["en-US"]: "/",
  ["de-DE"]: "/de/",
}

const IndexPage = ({ data }) => {
  let { frontPage } = data
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return (
    <Layout
      pullUp={frontPage.isNavbarOverlapping}
      navbarInWhite={frontPage.navbarInWhite}
    >
      <SEO
        metaImage={frontPage.metaImage && frontPage.metaImage.file.url}
        title={frontPage.pageTitle}
        canonicals={canonicals}
      />
      <ContentModules
        pullUp={frontPage.isNavbarOverlapping}
        allModules={frontPage.contentModules}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    frontPage: contentfulPage(
      isHomepage: { eq: "Yes" }
      node_locale: { eq: "de-DE" }
    ) {
      ...PageContent
    }
  }
`
